import axiosClient from "./axiosClient";

export const getBillingPlanList = () => {
  const url = `/billing`;
  return axiosClient.get(url);
};

export const assignBillingPlan = (param) => {
  const url = `/billing`;
  return axiosClient.post(url, param);
};

export const getCustomerId = (param) => {
  const url = `/billing/customer/${param}`;
  return axiosClient.get(url);
};

export const getCustomerPortal = (param) => {
  const url = `/billing/customer/${param}/portal`;
  return axiosClient.get(url);
};

export const getCustomerCheckout = (param) => {
  const url = `/billing/customer/${param}/checkout_url`;
  return axiosClient.get(url);
};

export const getPaymentIntent = (param) => {
  const url = `/billing/checkout_url/${param}`;
  return axiosClient.get(url);
};

export const setPaymentMethod = (params) => {
  const url = `/billing/payment_method`;
  return axiosClient.post(url, params);
};
