/* eslint-disable import/order */
import { Dialog, DialogActions } from "@mui/material";
import { useSnackbar } from "notistack";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/styles.css";
import { COMPANY_STATUS } from "../../../commons/enum";
import CustomButton from "../../../components/custom-button";
import { useCompanyApproval } from "../../../hooks/company.hooks";
import "./company-approval-dialog.scss";

const ApprovalDialog = ({
  company,
  isOpen = false,
  handleCloseDialog = () => {},
  isApprove = false,
}) => {
  const companyApproval = useCompanyApproval();
  const { enqueueSnackbar } = useSnackbar();

  const handleSendShareDetailsRequest = (status) => {
    return companyApproval.mutate(
      {
        id: company?.id,
        params: {
          status,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Request sent successfully", {
            variant: "success",
          });
          handleCloseDialog();
        },
        onError: (error) => {
          enqueueSnackbar(error?.data?.message, {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="company-approval-dialog-title"
      className="company-approval-dialog"
      open={isOpen}
    >
      <div className="company-approval__title">
        {`You are now ${isApprove ? "approve" : "reject"} `}
        <span>{company.name}</span>
        {` company`}
      </div>
      <DialogActions className="company-approval__button-container">
        {isApprove ? (
          <CustomButton
            className="approval-button--approve"
            autoFocus
            isLoading={companyApproval.isLoading}
            onClick={() =>
              handleSendShareDetailsRequest(COMPANY_STATUS.APPROVED)
            }
            label="Approve"
          />
        ) : (
          <CustomButton
            className="approval-button--reject"
            autoFocus
            isLoading={companyApproval.isLoading}
            onClick={() =>
              handleSendShareDetailsRequest(COMPANY_STATUS.REJECTED)
            }
            label="Reject"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

ApprovalDialog.propTypes = {};

export default ApprovalDialog;
